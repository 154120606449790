import {
  f7,
} from 'framework7-vue';
import moment from 'moment';
import Request from './Request';
import EventBus from '../js/event-bus';

let username = '';
let token = '';
let headerAuth = {};

// const baseUrl = 'https://adp.development.hispatecanalytics.com'; // Endpoint estable desarrollo
// const baseUrl = 'https://api.adp.pre.hispatecanalytics.com'; // Endpoint preproducción
const baseUrl = 'https://api.adp.hispatecanalytics.com'; // Endpoint producción

EventBus.$on('newLoggedUser', (payload) => {
  const data = JSON.parse(payload);
  username = data.username;
  token = data.token;
  headerAuth = `Bearer ${token}`;
});

export default {

  errors: {
    default: {
      name: 'error',
      message: 'error',
      callback() {},
    },

    E002: {
      name: 'invalid_token',
      message: 'Su sesión ha expirado',

      callback() {
        if (!username) return;
        username = null;
        EventBus.$emit('invalidTokenDetected');
        f7.dialog.alert(this.message);
      },
    },
  },
  getToken() {
    return token;
  },
  getHeaderAuth() {
    return headerAuth;
  },
  getBaseUrl() {
    return baseUrl;
  },
  // Login
  login(credentials) {
    const url = `${baseUrl}/user/authorization`;
    const payload = {
      data: JSON.stringify({
        username: credentials.username,
        password: credentials.password,
      }),
    };
    return Request.async(url, payload, 'POST', false, 'application/json')
      .then(this.handleLoginResult);
  },

  async handleLoginResult(result) {
    if (result.response !== '') {
      EventBus.$emit('newLoggedUser', result.response);
    }
  },

  //  Auxiliary methods
  async request(url, payload, verb, processData, contentType) {
    const response = await Request.async(url, payload, verb, processData, contentType);
    return response;
  },

  getException(errorcode) {
    return errorcode in this.errors ? this.errors[errorcode] : this.errors.default;
  },

  // ADP
  fetchOrders(init = '', end = '') {
    let url = `${baseUrl}/orders`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('init', init);
    }
    if (end !== '') {
      queryParams.set('end', end);
    }
    url += `?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getOrder(idOrder) {
    const url = `${baseUrl}/orders/${idOrder}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  updateOrder(idOrder, data) {
    const url = `${baseUrl}/orders/${idOrder}`;
    const payload = {
      data: JSON.stringify(data),
    };
    return Request.async(url, payload, 'PUT', false, 'application/json', headerAuth);
  },
  deleteOrder(idOrder) {
    const url = `${baseUrl}/orders/${idOrder}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'DELETE', false, 'application/json', headerAuth);
  },
  uploadOrder(data) {
    const url = `${baseUrl}/orders/parse`;
    const payload = {
      data: JSON.stringify(data),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },
  postOrder(data) {
    const url = `${baseUrl}/orders`;
    const payload = {
      data: JSON.stringify(data),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },

  fetchProfile() {
    const url = `${baseUrl}/profile`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  updateProfile(data) {
    const url = `${baseUrl}/profile`;
    const payload = {
      data: JSON.stringify(data),
    };
    return Request.async(url, payload, 'PUT', false, 'application/json', headerAuth);
  },
  checkInitEndDate(actualFilter) {
    let init = '';
    let end = '';
    if (typeof actualFilter !== 'undefined' && actualFilter.init !== 'Invalid date' && actualFilter.end !== 'Invalid date') {
      init = actualFilter.init;
      end = actualFilter.end;
    }
    if (init === '') {
      init = moment().subtract(7, 'days').format('YYYY-MM-DD');
      end = moment().format('YYYY-MM-DD');
    }
    return { init, end };
  },
};
