<template>
  <div
    class="content-devx content-dx-upload-file"
  >
    <f7-block class="profile">
      <input
        id="upload"
        type="file"
        accept="application/pdf"
        @change="onFileSelected"
      >
    </f7-block>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'UploadFile',
  data() {
    return {
      selectedFile: '',
    };
  },
  methods: {
    onFileSelected(event) {
      this.setNameAttachment('metro');
      const reader = new FileReader();
      const newFile = event.target.files[0];

      reader.onload = (e) => {
        this.selectedFile = `${e.target.result}`;
        this.setAttachment(this.selectedFile);
      };
      reader.onerror = (error) => {
        this.$f7.dialog.alert(`${this.$t('UploadFile_error')} ${error}`);
      };
      reader.readAsDataURL(newFile);
    },
    ...mapActions('Order', ['setAttachment', 'setNameAttachment', 'setIsUploaded']),

  },
};
</script>
