<template>
  <div>
    <DxDataGrid
      :data-source="dataOrders"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="false"
      :column-auto-width="true"
      :row-alternation-enabled="true"
      :word-wrap-enabled="true"
      @row-removed="callToDeleteOrder"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxOrders"
      />
      <DxEditing
        :allow-deleting="true"
        :confirm-delete="true"
        mode="popup"
      >
        <DxTexts
          :confirm-delete-message="$t('Confirm_Delete_Text')"
          :confirm-delete-title="$t('Confirm_Delete_Title')"
        />
      </DxEditing>
      <DxExport
        :enabled="false"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :show-info="true"
      />
      <DxHeaderFilter :visible="true" />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumnFixing :enabled="true" />
      <DxColumn
        data-field="customer"
        :caption="$t('OrderTable.client')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="order"
        :caption="$t('OrderTable.order')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="date_emmission"
        :caption="$t('OrderTable.date')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="date_deliver"
        :caption="$t('OrderTable.delivery')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="totalValue"
        :caption="$t('OrderTable.totalValue')"
        :calculate-cell-value="calculateAmount"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="status"
        :caption="$t('OrderTable.state')"
        alignment="center"
        :allow-header-filtering="true"
        cell-template="cellTemplate"
        :header-filter="{ dataSource: dataOrderStatus }"
      />
      <DxColumn
        type="buttons"
        :caption="$t('OrderTable.actions')"
      >
        <DxButton
          name="find"
          icon="find"
          :on-click="goToViewOrder"
        />
        <DxButton
          :element-attr="buttonAtributes"
          name="delete"
          icon="trash"
        />
      </DxColumn>
      <template #cellTemplate="{ data: dataOrders }">
        <div :class="setClassByStatus(dataOrders.data.status)">
          {{ $t(`OrderTable.status.${dataOrders.data.status}`) }}
        </div>
      </template>
    </DxDataGrid>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxScrolling,
  DxColumnFixing,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
  DxButton,
  DxEditing,
  DxTexts,
} from 'devextreme-vue/data-grid';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'ComponentOrders',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxScrolling,
    DxColumnFixing,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
    DxButton,
    DxEditing,
    DxTexts,
  },
  props: {
    dataOrders: { type: Array, default: () => [] },
  },
  data() {
    return {
      dataOrderStatus: [{
        text: this.$t('OrderTable.status.acepted'),
        value: 'acepted',
      }, {
        text: this.$t('OrderTable.status.sent'),
        value: 'sent',
      },
      {
        text: this.$t('OrderTable.status.pending'),
        value: 'pending',
      },
      {
        text: this.$t('OrderTable.status.cancel'),
        value: 'cancel',
      }],
      buttonAtributes: { id: 'removeButton', class: 'classButton' },
    };
  },
  methods: {
    async goToViewOrder(rowData) {
      this.setIsEdit(true);
      await this.getOrder(rowData.row.data.id);
      this.$f7.views.main.router.navigate('/createOrders/', { reloadCurrent: true });
    },
    async callToDeleteOrder(rowSelected) {
      this.$f7.preloader.show();
      try {
        if (rowSelected.data.status !== 'sent') {
          await this.deleteOrder(rowSelected.data.id);
          this.$f7.views.main.router.navigate('/orders/', { reloadCurrent: true });
        } else {
          this.$f7.dialog.alert('Los pedidos que tenga el estado enviado no se pueden eliminar.');
          this.$f7.views.main.router.navigate('/orders/', { reloadCurrent: true });
        }
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
      }
    },
    setClassByStatus(status) {
      let colorCell = '';
      switch (status) {
        case 'acepted':
          colorCell = 'Acepted';
          break;
        case 'sent':
          colorCell = 'Sent';
          break;
        case 'pending':
          colorCell = 'Pending';
          break;
        case 'cancel':
          colorCell = 'Cancel';
          break;
        default:
          break;
      }
      return colorCell;
    },
    calculateAmount(rowData) {
      let sumatory = 0;
      for (const article of rowData.articles) {
        sumatory += article.unit === '' ? article.price : (article.price * article.amount);
      }
      return parseFloat(sumatory).toFixed(2);
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'before',
        widget: 'dxButton',
        options: {
          icon: 'add',
          elementAttr: {
            class: 'add-task',
          },
          text: this.$t('DxDailyPlanning_text'),
          onClick: () => {
            this.setIsEdit(false);
            this.$f7.views.main.router.navigate('/createOrders/', { reloadCurrent: true });
          },
        },
      });
    },
    ...mapActions('Order', ['setIsEdit', 'deleteOrder', 'getOrder']),
  },
};
</script>

<style>
.Sent, .Cancel, .Pending, .Acepted {
  border-radius: 2px;
  font-size: 12px;
  padding: 4px 2px;
  font-weight: 600;
  text-transform: uppercase;
}

.Acepted {
  color: #219653 !important;
  background: #DEFCE8 !important;
  border-width: 1px;
    border-style: solid;
    border-color: #219653;
}
.Cancel {
  color: #74838C;
  background: #F4F5F7;
  border-width: 1px;
    border-style: solid;
    border-color: #74838C;
}
.Pending {
  color: #FF9200;
  background: white;
  border-width: 1px;
    border-style: solid;
    border-color: #FF9200;
}

.Sent {
  color: #23295e;
  background: white;
  border-width: 1px;
    border-style: solid;
    border-color: #23465E;
}
</style>
