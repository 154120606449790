// Others
import NotFoundPage from '../pages/404.vue';

// Automatización de pedidos.
import CreateOrders from '../pages/orders/createOrder/createOrder.vue';
import Orders from '../pages/orders/home.vue';
import NewUserRegister from '../pages/newUser/newUser.vue';

const routes = [
  // Default
  {
    path: '/',
    component: Orders,
  },
  // Automatización de pedidos.
  {
    path: '/orders/',
    component: Orders,
  },
  {
    path: '/createOrders/',
    component: CreateOrders,
  },
  {
    path: '/newUserRegister/',
    component: NewUserRegister,
  },
  // No found
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
