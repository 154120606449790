<template>
  <f7-page
    resizable
    @page:beforein="beforein"
  >
    <navbar :text="$t('LeftPanel_subtitle_home')" />
    <div
      v-if="loaded"
      class="content-devx"
    >
      <CalendarInitEndDate :key-name="keyName" />
      <Orders
        :data-orders="orders"
      />
    </div>
  </f7-page>
</template>

<script>

import { mapActions, mapState, mapGetters } from 'vuex';
import navbar from '../../components/navbar/NavBar.vue';
import Orders from '../../components/orders/index.vue';
import CalendarInitEndDate from '../../components/CalendarInitEndDate.vue';
import Api from '../../services/Api';

export default {
  name: 'PageOrder',
  components: {
    Orders,
    navbar,
    CalendarInitEndDate,
  },
  data() {
    return {
      loaded: false,
      keyName: 'orders',
    };
  },
  computed: {
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
    ...mapState('Order', ['orders']),
  },
  methods: {
    async beforein() {
      this.$f7.preloader.show();
      try {
        const checkedInitEndDate = Api.checkInitEndDate(this.actualFilter[this.keyName]);
        await this.fetchAllOrders(checkedInitEndDate);
      } catch (error) {
        if (error.message.includes('500')) {
          this.$f7.dialog.alert(this.$t('error_500'));
        } else {
          this.$f7.dialog.alert(this.$t(`${error}`));
        }
      } finally {
        this.loaded = true;
        this.$f7.preloader.hide();
      }
    },
    ...mapActions('Order', ['setIsEdit', 'fetchAllOrders']),
  },

};
</script>

<style lang="scss">

</style>
