<template>
  <div class="content-devx">
    <template>
      <form
        id="form"
        :ref="formRefName"
        method="post"
        action=""
        enctype="multipart/form-data"
      >
        <div class="dx-fieldset">
          <div class="dx-field">
            <div class="dx-field-label">
              {{ $t('') }}
            </div>
            <div class="fileuploader-container">
              <Attach-a-file />
            </div>
            <span class="note">
              {{ $t('text_under_LoadPdf') }}
            </span>
            <div>
              <f7-button
                class="dx-btn-upload"
                raised
                type="success"
                styling-mode="contained"
                @click="postAnalaysis"
              >
                {{ $t("loadPdf") }}
              </f7-button>
            </div>
          </div>
        </div>
      </form>
    </template>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import AttachAFile from './attachAFile.vue';

export default {
  name: 'UploadFile',
  components: {
    AttachAFile,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      actualPlot: '',
      formRefName: 'tree-view',
      sampleType: ['Agua', 'Suelo', 'Material vegetal', 'Solución suelo', 'Solución Fertilización de riego'],
      files: [],
      base64: '',
      actualDateSample: '',
      actualTypeSample: '',
      actualDescription: '',
      actualInterpretation: '',
    };
  },
  computed: {
    ...mapState('Order', ['newOrder']),
  },
  methods: {
    goToBack() {
      this.$f7.views.main.router.navigate('/orders/', { reloadCurrent: true });
    },
    async postAnalaysis() {
      this.$f7.preloader.show();
      try {
        await this.uploadOrder(this.newOrder);
        this.setIsEdit(false);
        this.setIsUploaded(true);
        this.$f7.views.main.router.navigate('/createOrders/', { reloadCurrent: true });
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
      }
    },
    ...mapActions('Order', ['uploadOrder', 'setIsEdit', 'setIsUploaded']),
  },
};
</script>

<style scoped>
.finishedRegister {
    margin-left: 55%;
}
.center{
  padding-top: 3%;
  font-weight: bold;
}
#form {
    max-width: 600px;
    margin: auto;
}

.button {
    margin-top: 50px;
    margin-right: 20px;
    float: right;
}

.fileuploader-container{
    border: 0px solid #d3d3d3;
    align-content: center;
}

#form h3 {
    margin-left: 20px;
    font-weight: normal;
    font-size: 22px;
}
.note {
  margin-left: 40%;
}
.dx-btn-upload {
  background: var(--adb-gray);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  height: auto;
  line-height: 3;
  box-shadow: none;
  color: white!important;
  margin-right: 50%;
}
</style>
