var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('f7-row',{staticClass:"prueba"},[_c('f7-col',{staticStyle:{"width":"60%"}},[_c('div',{staticClass:"content-devx"},[_c('DxForm',{attrs:{"id":"orderForm"}},[_c('DxGroupItem',{attrs:{"css-class":"content-combo","col-count":2}},[_c('DxSimpleItem',{attrs:{"name":"client","data-field":_vm.$t('newOrder.newOrder_client'),"editor-options":{
                value: _vm.newOrder.customer,
                readOnly: _vm.newOrder.status === 'sent',
                onValueChanged: _vm.onChangeClient,
              }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('newOrder.newUser.DxCostForm_Id_required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"order","data-field":_vm.$t('newOrder.newOrder_order'),"editor-options":{
                value: _vm.newOrder.order,
                readOnly: _vm.newOrder.status === 'sent',
                onValueChanged: _vm.onChangeOrder,
              }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('newOrder.newUser.DxCostForm_Id_required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"issued","data-field":_vm.$t('newOrder.newOrder_emit'),"editor-options":{
                value: _vm.newOrder.date_emmission,
                readOnly: _vm.newOrder.status === 'sent',
                onValueChanged: _vm.onChangeIssued,
              }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('newOrder.newUser.DxCostForm_Id_required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"delivery","data-field":_vm.$t('newOrder.newOrder_delivery'),"editor-options":{
                value: _vm.newOrder.date_deliver,
                readOnly: _vm.newOrder.status === 'sent',
                onValueChanged: _vm.onChangeDelivery,
              }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('newOrder.newUser.DxCostForm_price_required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"email","data-field":_vm.$t('newOrder.newOrder_address'),"col-span":2,"editor-options":{
                value: _vm.newOrder.destiny,
                readOnly: _vm.newOrder.status === 'sent',
                onValueChanged: _vm.onChangeDirection,
              }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('newOrder.newUser.DxCostForm_Id_required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"status","data-field":_vm.$t('status'),"editor-type":"dxSelectBox","editor-options":{
                items: _vm.newOrder.status === 'sent' ? ['sent'] : _vm.listStatus,
                value: _vm.newOrder.status,
                displayExpr: _vm.displayTranslatedStatus,
                readOnly: _vm.newOrder.status === 'sent',
                onValueChanged: _vm.onChangeStatus,
              }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('newOrder.newUser.DxCostForm_Id_required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('OrderTable.totalValue'),"editor-type":"dxNumberBox","editor-options":{
                value: parseFloat(_vm.sumatory).toFixed(2),
                readOnly: true,
                min: 0,
              }}})],1)],1),_vm._v(" "),_c('div',{staticClass:"content-datagrid"},[_c('DxDataGrid',{attrs:{"data-source":_vm.newOrder.articles,"allow-column-reordering":true,"show-borders":true,"show-column-lines":true,"show-row-lines":false,"column-auto-width":true,"row-alternation-enabled":true,"word-wrap-enabled":true}},[_c('DxSelection',{attrs:{"mode":"single"}}),_vm._v(" "),_c('DxStateStoring',{attrs:{"enabled":true,"type":"localStorage","storage-key":"DxViewOrder"}}),_vm._v(" "),_c('DxExport',{attrs:{"enabled":false}}),_vm._v(" "),_c('DxPaging',{attrs:{"page-size":10}}),_vm._v(" "),_c('DxPager',{attrs:{"show-page-size-selector":true,"show-info":true}}),_vm._v(" "),_c('DxHeaderFilter',{attrs:{"visible":true}}),_vm._v(" "),_c('DxSearchPanel',{attrs:{"visible":true,"width":200,"placeholder":_vm.$t('Search_placeholder')}}),_vm._v(" "),_c('DxColumnFixing',{attrs:{"enabled":true}}),_vm._v(" "),_c('DxColumn',{attrs:{"data-field":"article","caption":_vm.$t('OrderTable.article'),"allow-header-filtering":false,"alignment":"left"}}),_vm._v(" "),_c('DxColumn',{attrs:{"data-field":"original.description","caption":_vm.$t('OrderTable.description'),"allow-header-filtering":false,"alignment":"left"}}),_vm._v(" "),_c('DxColumn',{attrs:{"data-field":"amount","caption":_vm.$t('OrderTable.quantity'),"allow-header-filtering":false,"alignment":"left"}}),_vm._v(" "),_c('DxColumn',{attrs:{"data-field":"unit","caption":_vm.$t('OrderTable.unit'),"allow-header-filtering":false,"alignment":"left"}}),_vm._v(" "),_c('DxColumn',{attrs:{"data-field":"price","caption":_vm.$t('OrderTable.price'),"allow-header-filtering":false,"alignment":"left"}})],1)],1)],1)]),_vm._v(" "),_c('f7-col',[_c('div',{staticClass:"content-devx"},[_c('embed',{attrs:{"src":_vm.newOrder.file,"width":"100%","frameborder":"0"}})])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }