<template>
  <f7-page resizable>
    <navbar
      v-if="!isEdit"
      :text="`${$t('navbar_title_createOrder')}`"
    />
    <navbar
      v-else
      :text="`${$t('navbar_title_viewOrder')}`"
    />
    <f7-block>
      <div v-if="!isUploaded && !isEdit">
        <UpFile />
      </div>
      <div v-else>
        <ViewOrder />
      </div>
      <div>
        <div v-if="isEdit || isUploaded">
          <f7-row>
            <f7-col style="width: 10%;margin-left: 75%">
              <f7-button
                name="btnSignIn"
                class="botonSecundary"
                col
                button
                button-large
                button-raised
                fill
                style="color:#FFFFFF;"
                @click="back"
              >
                {{ $t("cancel") }}
              </f7-button>
            </f7-col>
            <f7-col
              v-if="isEdit && newOrder.status !== 'sent'"
              style="width: 10%"
            >
              <f7-button
                name="btnSignIn"
                class="botonPrimary"
                col
                button
                button-large
                button-raised
                fill
                style="color:#FFFFFF"
                @click="saveOrder"
              >
                {{ $t("save") }}
              </f7-button>
            </f7-col>
            <f7-col
              v-else-if="!isEdit && newOrder.status !== 'sent'"
              style="width: 10%"
            >
              <f7-button
                name="btnSignIn"
                class="botonPrimary"
                col
                button
                button-large
                button-raised
                fill
                style="color:#FFFFFF"
                @click="setOrder"
              >
                {{ $t("save") }}
              </f7-button>
            </f7-col>
          </f7-row>
        </div>
      </div>
    </f7-block>
  </f7-page>
</template>

<script>

import { mapState, mapActions } from 'vuex';
import navbar from '../../../components/navbar/NavBar.vue';
import UpFile from '../../../components/orders/createOrder/loadOrder/index.vue';
import CreateNewOrder from '../../../components/orders/createOrder/index.vue';
import ViewOrder from '../../../components/orders/viewOrder/index.vue';

export default {
  name: 'CreateOrders',
  components: {
    navbar,
    CreateNewOrder,
    ViewOrder,
    UpFile,
  },
  data() {
    return {
      dataArticles: [],
    };
  },
  computed: {
    ...mapState('Order', ['isEdit', 'orders', 'newOrder', 'isUploaded']),
  },
  methods: {
    back() {
      this.setIsUploaded(false);
      this.setIsEdit(false);
      this.$f7.views.main.router.navigate('/orders/', { reloadCurrent: true });
    },
    async saveOrder() {
      try {
        await this.updateOrder(this.newOrder);
        this.$f7.views.main.router.navigate('/orders/', { reloadCurrent: true });
        this.resetNewOrder();
        this.setIsEdit(false);
        this.setIsUploaded(false);
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      }
    },
    async setOrder() {
      try {
        await this.postOrder(this.newOrder);
        this.$f7.views.main.router.navigate('/orders/', { reloadCurrent: true });
        this.setIsEdit(false);
        this.setIsUploaded(false);
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      }
    },
    ...mapActions('Order', ['updateOrder', 'uploadOrder', 'postOrder', 'resetNewOrder', 'setIsUploaded',
      'setIsEdit']),
  },
};
</script>

<style lang="scss">

</style>
