import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

// Modules
import authentication from './modules/authentication';
import User from './modules/User';
import Utilities from './modules/Utilities';
import AccessControl from './modules/AccessControl';
import Order from './modules/Order';
import CalendarInitEndDate from './modules/CalendarInitEndDate';

Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    authentication,
    User,
    Utilities,
    Order,
    AccessControl,
    CalendarInitEndDate,
  },
  plugins: [new VuexPersistence({
    storage: window.localStorage,
  }).plugin],
});

store.dispatch('authentication/initialize');

export default store;
