<template>
  <div>
    <f7-block>
      <div class="text-align-center">
        <img
          class="img_logo"
          :src="HISPATEC"
          :alt="APPLICATION_NAME"
        >
        <!-- <span class="titleLogin"> {{ 'VegSyst-DSS' }} </span> -->
      </div>
      <div
        v-if="!isNewUser"
        class="content-devx"
      >
        <f7-list
          form
          no-hairlines-between
        >
          <f7-list-item
            name="txtWelcome"
            class="login label-text"
          >
            {{ $t("Login_txtWelcome") }}
          </f7-list-item>
          <!-- Username -->
          <f7-list-input
            name="inpUsername"
            outline
            item-label
            type="text"
            :placeholder="$t('Login_inpUsername_placeholder')"
            clear-button
            max="255"
            required
            validate
            :error-message="$t('Login_inpUsername_validate')"
            @input="username = $event.target.value"
          />
          <!-- Password -->
          <f7-list-input
            name="inpPassword"
            outline
            item-label
            type="password"
            :placeholder="$t('Login_inpPassword_placeholder')"
            clear-button
            max="255"
            required
            autocomplete="current-password"
            validate
            :error-message="$t('Login_inpPassword_validate')"
            @input="password = $event.target.value"
          />
        </f7-list>

        <!-- Buttons -->
        <f7-list>
          <f7-button
            name="btnSignIn"
            class="botonPrimary"
            col
            button
            button-large
            button-raised
            fill
            :disabled="btnDisable"
            style="color:#FFFFFF"
            @click="signIn"
          >
            {{ $t("Login_btnSignIn") }}
          </f7-button>
        </f7-list>
        <div
          v-if="hasSignUp"
          name="btnSignUp"
          @click="signUp"
        >
          {{ $t("Login_btnSignUp_question") }}
          <strong>{{ $t("Login_btnSignUp_link") }}</strong>
        </div>
      </div>
    </f7-block>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { APPLICATION_NAME, APP_LOGO, HISPATEC } from '../../js/constants';
import Api from '../../services/Api';
import NewUser from '../newUser/index.vue';

export default {
  name: 'Login',
  components: {
    NewUser,
  },
  props: {
    hasRestorePass: { type: Boolean, default: false },
    hasSignUp: { type: Boolean, default: false },
    hasRememberMe: { type: Boolean, default: false },
    hasNeedHelp: { type: Boolean, default: false },
  },
  data() {
    return {
      username: '',
      password: '',
      APPLICATION_NAME,
      APP_LOGO,
      HISPATEC,
    };
  },
  computed: {
    btnDisable() {
      return this.username && !this.password;
    },
    appLogo() {
      return localStorage.getItem('applogo') || this.APP_LOGO;
    },
    ...mapState('authentication', ['isLogged', 'wantToRemember']),
    ...mapState('User', ['isNewUser']),
  },
  methods: {
    async signIn() {
      this.$f7.preloader.show();
      try {
        await Api.login({
          username: this.username,
          password: this.password,
        });
        this.$f7.views.main.router.navigate('/orders/', { reloadCurrent: true });
      } catch (reason) {
        const message = this.$helpers.getFilteredErrorMessage(reason);
        if (message.includes('incorrect authentication')) {
          this.$f7.dialog.alert(this.$t(message));
        }
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      } finally {
        this.$f7.preloader.hide();
      }
    },
    signUp() {
      const self = this;
      const router = self.$f7router;
      router.navigate('/recoveryPasswordPage/', { reloadCurrent: true });
    },
    quit() {
      const self = this;
      const router = self.$f7router;
      router.back();
    },
    newUser() {
      this.setIsNewUser(true);
    },
    ...mapActions('authentication', ['login']),
    ...mapActions('User', ['setIsNewUser']),
    ...mapMutations('authentication', ['setWantToRemember', 'wantToRecoveryPass', 'changePassword']),
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/accounts/login.scss";

</style>
